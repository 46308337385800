//shared styles from published library @domgen/dgx-fe-styles-core
@use 'base-styles/base-styles';
//overrides for application from libs/shared/ui-claims-legacy-styles
@use 'claims-legacy-styles/claims-legacy-styles';

//themes
@use 'base-styles/themes/ao';

@use 'base-styles/abstracts/typography-presets/dg-new-styles-v2' as tp;

.claims {
  @include ao.ao();
  //override typography for now as updated rules not yet included in sales
  @include tp.set-vars();
}
.grecaptcha-badge {
  visibility: hidden;
}
