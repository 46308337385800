// these legacy styles can be removed once migrated to new form builder
@use "base-styles/abstracts/forms" as f;
@use 'base-styles/abstracts/spacing' as s;
@use "base-styles/abstracts/typography" as t;
@use "base-styles/abstracts/breakpoints" as bp;

form {
  width: 100%;
}

.form-control {
  @include f.box-wrapper();
  input,
  textarea,
  select {
    @include f.textfield();
  }
}

.legacy-form-builder {
  &.form-input {
    margin-bottom: 16px;
    position: relative;

    // this is for with multiple error messages, although logic seems wrong
    // if we have two and they have 10px Y margin by default
    // surely we want to get rid of top margin
    // todo: review in browser
    .form-input-feedback + .form-input-feedback {
      margin-bottom: 0;
    }
  }

  &.form-input,
  &.form-group {
    label,
    .label {
      display: inline-block;
      @include f.text--label();
      @include s.margin-bottom(1);
      margin-bottom: 11px;
    }

    .form-control {
      margin: 0;
    }
  }

  .form-control {
    .validation-icon {
      @include f.validation-icon();
    }

    textarea,
    select {
      //todo: seems like this could go in shared styles
      resize: none;
    }
  }

  &.is-invalid .form-control {
    input:not(.mat-input-element):not(.pure):not([type='radio']),
    textarea,
    select {
      transition: all 0.8s ease-in-out;
      @include f.box--invalid();
    }

    .validation-icon.icon-error-circle {
      @include f.validation-icon--invalid();
    }
  }

  &.is-valid .form-control {
    input:not(.mat-input-element):not(.pure):not([type='radio']),
    textarea,
    select {
      @include f.box--valid();
    }

    .validation-icon.icon-tick-circle {
      @include f.validation-icon--valid();
    }
  }

  //todo: what is this for? (presume validation icons)
  .form-control:after {
    content: '';
    transition: all 0.2s ease-in-out;
    transform: scale(0);
    z-index: 1;
  }

  //todo this means something else in base-styles now - rename usages to closer align with sales
  .form-input-feedback {
    @include f.error__text();
    display: block;
    margin: 7px 0;
  }

  //todo: this should be agreed as standard requirement and moved to shared styles
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus,
  select:-webkit-autofill:active {
    // this prevents the color of the field from changing when it has been autofilled
    // for some reason setting the background property does not work
    // this trick effectively changes the background color though
    // 1000px is an arbitrarily high number just so we never only partially cover the field
    -webkit-box-shadow: 0 0 0 1000px var(--input-background-color) inset !important;
  }

  //todo: this should be agreed if it is standard and possibly moved to shared styles
  //      perhaps better to use "rows" config on text-area?
  textarea {
    min-height: 140px;
    padding-bottom: 20px;

    @include bp.breakpoint(md) {
      min-height: 105px;
      padding-bottom: 8px;
      padding-top: 8px;
    }
  }

  /*tooltip related */
  //todo: can we use reusable tooltips as per sales?
  .tooltip {
    position: absolute;
    top: 1px;
    right: 8px;
    text-align: center;
    background-color: var(--primary-interactive-color);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 26px;
    cursor: default;

    &:before {
      content: '?';
      //todo: use icon instead of text
      font-family: Arial, sans-serif;
      font-weight: bold;
      color: var(--primary-interactive-contrast-color);
    }

    &:hover {
      + p {
        display: block;
        z-index: 1;
        transform-origin: 100% 0;
        -webkit-animation: fadeIn 0.3s ease-in-out;
        animation: fadeIn 0.3s ease-in-out;
      }
    }

    + p {
      display: none;
      text-align: left;
      padding: 20px;
    }
  }
}
